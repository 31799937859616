.app {
    text-align: center;
    display: flex;
    justify-content: center;
}

.link-account .email-input {
    margin: 20px 0;
}

.link-account .email-input input {
    width: 300px;
    margin-top: 8px;
    padding: 8px;

    font-size: 16px;
    text-align: center;
}

.link-account .site-list ul {
    padding: 0;
}

.link-account .site-list li {
    list-style: none;
    padding: 8px;
}

.link-account .site-list li:hover {
    background-color: #aaccff;
    cursor: pointer;
}